<template>
<div v-if="formElements.length>0 && showElement && status" class="pt-2" :id="newElementId">
  <div :class="{'list-group': true, 'my-list-group-no-border':(isRoot /*&& hasPages*/ ) }" class="list-group-flush2" >
    <b-list-group-item v-if="(!isRoot) && showHeaderComputed" variant="dark" @click="opened=!opened">
      <span class="glyphicon" :class="{'glyphicon-chevron-right':!opened,'glyphicon-chevron-down':opened}"></span>
      <span v-html="label"></span>
      <b-btn-group v-if="$store.state.allowCopyPaste" class="ml-2">
        <b-btn @click.stop="copyValToClipboard()" size="sm"><i class="glyphicon glyphicon-copy"></i></b-btn>
        <b-btn @click.stop="getValFromClipboard()" size="sm" v-if="!readonly"><i class="glyphicon glyphicon-paste"></i></b-btn>
      </b-btn-group>
    </b-list-group-item>
    <div v-else-if="isRoot && $store.state.allowCopyPaste">
      Survey:
      <b-btn-group>
        <b-btn @click.stop="copyValToClipboard()" size="sm"><i class="glyphicon glyphicon-copy"></i></b-btn>
        <b-btn @click.stop="getValFromClipboard()" size="sm" v-if="!readonly"><i class="glyphicon glyphicon-paste"></i></b-btn>
      </b-btn-group>
    </div>

    <b-list-group-item v-show="opened" :class="{'bg-warning': isDeleted}">
      <component v-for="(el, index) in formElements"
        :is="getComponentName(el)"
        :config="el"
        :key="el.name"
        :id="'group-page-'+newElementId+index"
        :formReadOnly="formReadOnly"
        :formDefinition="formDefinition"
        :value="myValue?.[el.name]"
        @input="updateValue($event, el.name)"
        :status="status?.[el.name]"
        :surveyDoc="surveyDoc"
        :showSystemValues="showSystemValues"
        :namePath="newNamePath"
        :editingIndexGiven="editingIndexGiven"
        :readOnlyInput="readOnlyInput"
        >
      </component>
      <div v-if="parameters.print && groupStatus < 15">
        <b-btn  @click="showPrint" variant="primary" class="ml-5 mt-5"><i class="glyphicon glyphicon-print"></i></b-btn>
      </div>
    </b-list-group-item>
  </div>
  

</div>
</template>

<script>
import base from './input/_baseInput.js'
import inputs from './input'
import transcodeType from './input/_transcodeType.js'
import range from 'lodash/range'
import cuid from 'cuid'
import fieldViewUtils from '../utils/fieldViewUtils'


export default {
  name:'IGroup',
  mixins:[base],
  props:{
    showHeader:{
      type:Boolean,
      default:true
    },
    isRootRepeat:{
      type:Boolean,
      default:false
    },
    editingIndexGiven:{
      type:Number,
      default:null
    }
  },
  components:inputs,
  data(){
    return {
      opened:true,
      // currentPage:this.page_id?(parseInt(this.page_id)-1):0
      newElementId:cuid(),
    }
  },
  beforeMount(){
    if (this.appearance=='minimal'){
      this.opened=false
    }
  },
  computed:{
    isDeleted(){
      return this.surveyDoc.survey_deleted
    },
    newNamePath(){
      let new1=this.namePath.slice()
      if(!this.isRoot){
        if(this.isRootRepeat==false){
          new1.push(this.config.name)
        }
      }
      return new1
    },
    showHeaderComputed(){
      if(this.appearance_aecom.indexOf('no-title')!=-1){
        return false
      }
      return this.showHeader
    },
    hasViewMode23Active(){
      return fieldViewUtils.isHiddenInViewMode(3, this, this.groupStatus) || fieldViewUtils.isHiddenInViewMode(2, this, this.groupStatus)
    },
  },
  watch:{
    hasViewMode23Active(){
      if(this.hasViewMode23Active == true && this.isRoot == false){
        this.opened = false
      }else{
        this.opened = true
      }
    },
  },
  methods:{
    getComponentName(el){
      return transcodeType.transcode(el)
    },
    showPrint(){
      this.$store.commit('form/editShowItem',{value: 3, name: this.parameters.print})
    },
    updateValue(value, name){
      if(this.myValue===null || this.myValue===undefined){
        this.myValue={}
      }
      this.$set(this.myValue, name, value)
    },
  }

}
</script>

<style scoped>
/*  */
.my-list-group-no-border .list-group-item:first-child {
  border-right:0;
  border-left:0;
  border-top:0;
  border-bottom:0;
      /* border-right-width: 0px; */
}

</style>
