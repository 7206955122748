import moment from 'moment'
import cuid from 'cuid'

export default {
  //ref for functions
  'null'(){ //the expression parser think of null as a variable(if not within quote), so we define it.
    return null
  },
  isNull(val){
    if(val=='null') return true
    if(val===0) return false
    if(val===false) return false
    if(val) return false
    return true
  },
  hasValue(val){
    if(this.isNull(val)){
      return false
    }
    if(val && val != 0 ){
      return true
    }
    return false
  },
  isNumber(val){
    return ! isNaN(val)
  },
  length(val){
    if (this.isNull(val)){
      return 0
    }
    return String(val).trim().length
  },
  getVal(val, prop){
    // TODO: prop could be a path, that would ease many use case.
    if (val){
      return val[prop]
    }
    return null
  },
  selected(myval,condition){
    if(!myval){return false}
    if(Array.isArray(myval)){
      if(myval.indexOf(condition)>-1){
        return true
      }
      return false
    }
    if(myval==condition) return true
    return false
  },
      //   // prefer to user selected for those conditions...
  // indexOf(inString,searchString){ //can also be an array
  //   if(inString.indexOf(searchString)>-1){
  //     return true
  //   }
  //   return false
  // }
  'countselected'(myarray){
    if(myarray=='null') return 0
    if(myarray===null) return 0
    if(Array.isArray(myarray)){
      return myarray.length
    }
    return 1 //is has a value. it's not null.
  },
  'count'(myarray){
    if(myarray=='null') return 0
    if(myarray===null || myarray===undefined) return 0
    if(Array.isArray(myarray)){
      return myarray.length
    }
    return 1 //is has a value. it's not null.
  },
  'countequal'(myarray,condition){
    if(myarray=='null') return 0
    if(myarray===null || myarray===undefined) return 0
    return myarray.filter(x=>x==condition).length
  },
  'sum'(myarray){
    if(myarray=='null') return 0
    if(myarray===null) return 0
    var total = 0;
    for(var i=0,n=myarray.length; i<n; ++i){
      //https://stackoverflow.com/a/175787/140384
      if(myarray[i] && isNaN(myarray[i])===false){ //check if number
        total += +myarray[i]; // convert to number if string
      }
    }
    return total
  },
  'arrayMax'(myarray){
    if(myarray=='null') return 0
    if(myarray===null) return 0
    var maxVal = -Infinity;
    for(var i=0,n=myarray.length; i<n; ++i){
      //https://stackoverflow.com/a/175787/140384
      if(myarray[i] !== null && myarray[i] !== undefined && isNaN(myarray[i])===false && +myarray[i]>maxVal){ //check if number
        maxVal = +myarray[i]; // convert to number if string
      }
    }
    return maxVal
  },
  'arrayMin'(myarray){
    if(myarray=='null') return 0
    if(myarray===null) return 0
    var minVal = Infinity;
    for(var i=0,n=myarray.length; i<n; ++i){
      //https://stackoverflow.com/a/175787/140384
      if(myarray[i] !== null && myarray[i] !== undefined && isNaN(myarray[i])===false && +myarray[i]<minVal){ //check if number
        minVal = +myarray[i]; // convert to number if string
      }
    }
    return minVal
  },
  'arrayValues'(myarray, val){
    if(myarray=='null') return []
    if(myarray===null) return []
    if(Array.isArray(myarray)){
      return myarray.map(x=>x[val])
    }
    return []
  },
  'sumif'(myarray,arrCondition,condition,arrCondition2=null,condition2=null){
    // console.log('sumif',myarray,arrCondition,condition,arrCondition2,condition2);
    // TODO: make this more robust and support more than 2 conditions.
    if(myarray=='null') return 0
    if(myarray===null) return 0
    if(myarray.length!=arrCondition.length) return null
    if(arrCondition2 && myarray.length!=arrCondition2.length) return null
    let total = 0;
    for(var i=0,n=myarray.length; i<n; ++i){
      let toSum = true
      //https://stackoverflow.com/a/175787/140384
      if(!myarray[i] || !isNaN(myarray[i])===false){
        toSum = false
      }
      if(Array.isArray(condition)){
        if( condition.indexOf(arrCondition[i]) == -1 ){ //check if number and condition
          toSum = false
        }
      }else{
        if(arrCondition[i]!=condition ){ //check if number and condition
          toSum = false
        }
      }
      if(condition2 && Array.isArray(condition2)){
        if( condition2.indexOf(arrCondition2[i]) == -1 ){ //check if number and condition
          toSum = false
        }
      }else if(condition2){
        if(arrCondition2[i]!=condition2 ){ //check if number and condition
          toSum = false
        }
      }
      if(toSum){
        total += +myarray[i]; // convert to number if string
      }
    }
    return total
  },
  'countif'(arrCondition,condition){
    if(arrCondition=='null') return 0
    if(arrCondition===null) return 0
    if(arrCondition.length!=arrCondition.length) return null
    let total = 0;
    for(var i=0,n=arrCondition.length; i<n; ++i){
      let toSum = true
      //https://stackoverflow.com/a/175787/140384
      if(!arrCondition[i] || !isNaN(arrCondition[i])===false){
        toSum = false
      }
      if(Array.isArray(condition)){
        if( condition.indexOf(arrCondition[i]) == -1 ){ //check if number and condition
          toSum = false
        }
      }else{
        if(arrCondition[i]!=condition ){ //check if number and condition
          toSum = false
        }
      }
      if(toSum){
        total += 1
      }
    }
    return total
  },
  'arrayConditions'(myarray,condition, operator='=='){
    // return an array of same length as myarray. value as 0 or 1 if condition met. 
    // Usually used in conjunction with other array functions.
    // condition can be an array.
    // supported operators: ==, !=, >, <, >=, <=
    if(myarray=='null') return []
    if(myarray===null) return []
    if(Array.isArray(myarray)){

      const checkCondition = (value, condition, operator) => {
        if(typeof condition === 'string'){
          switch(operator){
            case '==':
              return value === condition
            case '!=':
              return value !== condition
            case '>':
              return value > condition
            case '<':
              return value < condition
            case '>=':
              return value >= condition
            case '<=':
              return value <= condition
          }
        }else if(typeof condition === 'number'){
          let valueNum = parseFloat(value)
          switch(operator){
            case '==':
              return valueNum === condition
            case '!=':
              return valueNum !== condition
            case '>':
              return valueNum > condition
            case '<':
              return valueNum < condition
            case '>=':
              return valueNum >= condition
            case '<=':
              return valueNum <= condition
          }
        }
      }

      let rep=[]
      myarray.map((x,i)=>{
        let condition0=condition 
        if (Array.isArray(condition)){
          condition0=condition[i]
        }
        if (checkCondition(x, condition0, operator)){
          rep.push(1)
        }else{
          rep.push(0)
        }
      })
      return rep
    }
    return []
    
  },
  'duplicates'(myarray){
    if(myarray=='null') return 0
    if(myarray===null) return 0
    if(Array.isArray(myarray)){
      var obj1 = {}
      let nbDuplicates=0
      myarray.map(x=>{          
        //https://stackoverflow.com/a/9229821/140384
        if(obj1.hasOwnProperty(x)){
          nbDuplicates+=1
        }else{
          obj1[x]=true
        }
      })
      return nbDuplicates
    }
    return 0 //is has a value. it's not null. and no duplicates found
  },
  'removeDuplicates'(myarray){
    if(myarray=='null') return null
    if(myarray===null) return null
    if(Array.isArray(myarray)){
      const obj1 = {}
      const rep = []
      myarray.map(x=>{          
        if(!obj1.hasOwnProperty(x)){
          obj1[x]=true
          rep.push(x)
        }
      })
      return rep
    }
    return null 
  },
  now(){
    return moment().utc().format()
  },
  today(){
    return moment().utc().format('YYYY-MM-DD')
  },
  age(val){
    return moment().diff(val, 'years')
  },
  cuid(){
    return cuid()
  },
  'choicesAttributes'(value,choicesArray,attributeToGet, default_val=null){
    if((!value) || (!choicesArray) || choicesArray.length==0) return null
    // const choices=choicesArray.filter(x=>x.name==value)
    // if(choices.length==1){
    //   return choices[attributeToGet]
    // }else if (choices.length>1){
    //   const choices2= choices.map(x=>x[attributeToGet])
    // }
    // ** better for now as we have many that have the same name and they all have the same attribute values.
    // also probably faster as we exit on first occurance.
    for(let i=0;i<choicesArray.length;i++){
      if(choicesArray[i].name==value){
        const val1 = choicesArray[i][attributeToGet]
        if (val1){
          return val1
        }else{
          break // them will return default val.
        }
      }
    }
    return default_val
  },
  'choiceSelectByAttributeConditions'(value, choicesArray, attributesForCondition, default_val=null, returnProps='name'){
    if((!value) || (!choicesArray) || choicesArray.length==0 || attributesForCondition.length==0) return default_val
    // this a tricky one, we have to find the first occurance of the value in the array.
    // The value should be an Object with the keys as the choicesArray, specified by the attributesForCondition.
    // It return the name of the corresponding Choice.
    // We use it fro example to categorize a value in a Choice list.
    // The first item that fir the condition is returned. We order the choicesArray with order to have the most important first.
    
    let arraySorted = choicesArray.sort((a, b) => (+a.order > +b.order) ? 1 : -1)
    
    for(let i2=0;i2<arraySorted.length;i2++){
      const x = arraySorted[i2]
      let found = true
      for(let i=0;i<attributesForCondition.length;i++){
        // attributeconditin can be all values if empty or *
        if(x[attributesForCondition[i]]=='*' || !x[attributesForCondition[i]]){
          continue
        }else if(Array.isArray(value[attributesForCondition[i]])){
          if(value[attributesForCondition[i]].indexOf(x[attributesForCondition[i]])==-1){
            found = false
            break
          }
        }else if(x[attributesForCondition[i]]!=value[attributesForCondition[i]]){
          found = false
          break
        }
      }
      if(found){
        return x[returnProps]
      }
    }
    return default_val
  },
  concat(...strToConcat){
    return strToConcat.join('')
  },
  concat2(separator, ...strToConcat){
    return strToConcat.join(separator)
  },
  // some array function
  'getequal'(myarray,condition){
    try{
      const rep=[]
      myarray.map((x,i)=>{
        if(x==condition){
          rep.push(i)
        }
      })
      return rep
    }catch(e){
      return null
    }
  },
  concatarrays(separator,...strToConcat){
    // test: concatarrays(';',[2,23,4],[77,77]) => '2;23;4;77;77'
    // test: concatarrays(';',[],[]) => ''
    try{
      return strToConcat.filter(y=> y && y.length>0).map(x=>x.join(separator)).join(separator)
    }catch(e){
      return null
    }
  },
  flattenArrays(...toFlatten){
    // test: flattenArrays([2,23,4],[77,77]) => '2;23;4;77;77'
    // test: flattenArrays([],[77,77]) => '2;23;4;77;77'
    // test: flattenArrays([],[]) => ''
    try{
      let rep=[]
      toFlatten.filter(y=> y && y.length>0).map(x=>{
        rep = rep.concat(x)
      })
      return rep
    }catch(e){
      return null
    }
  },
  'getIndex'(myarray,index){
    try{
      if(Array.isArray(index)){
        return myarray.filter((x,i)=>index.indexOf(i)>-1)
      }else{
        return myarray[index]
      }
    }catch(e){
      return null
    }
  },
  arrayMultiplication (arr1,arr2){
    try{
      let maxi=arr1.length
      maxi=arr2.length<maxi?arr2.length:maxi
      let rep=[]
      for(let i=0;i<maxi;i++){
        try{
          rep.push((+arr1[i])*(+arr2[i]))
        }catch(e){
          rep.push(null)
        }
      }
      return rep
    }catch(e){
      return null
    }
  },
  arrayDivision (arr1,arr2){
    try{
      let maxi=arr1.length
      maxi=arr2.length<maxi?arr2.length:maxi
      let rep=[]
      for(let i=0;i<maxi;i++){
        try{
          rep.push((+arr1[i])/(+arr2[i]))
        }catch(e){
          rep.push(null)
        }
      }
      return rep
    }catch(e){
      return null
    }
  },
  arrayWeightedMean (arr1,arr2){
    try{
      const reducer = (accumulator, currentValue) => (accumulator + (+currentValue));
      const sum1=arr2.reduce(reducer)
      let maxi=arr1.length
      maxi=arr2.length<maxi?arr2.length:maxi
      let rep=[]
      for(let i=0;i<maxi;i++){
        try{
          rep.push((+arr1[i])*(+arr2[i]/sum1))
        }catch(e){
          rep.push(null)
        }
      }
      return rep.reduce(reducer)
    }catch(e){
      return null
    }
  },
  myRound(val,nbDecimal){
    try{
      return Math.round((+ val)*( Math.pow(10,nbDecimal))) / (Math.pow(10,nbDecimal))
    }catch(e){
      return null
    }
  },
  padNumber(toPad, width, z) {
    // mix of answer of: https://stackoverflow.com/questions/10073699/pad-a-number-with-leading-zeros-in-javascript
    z = z || '0';
    toPad = toPad + '';
    if (toPad.length >= width){
      return toPad
    }
    return (z.repeat(width) + toPad).slice(-1 * width);
  },
  duration(){
    // Just to avoid SurveyCTO import errors
    return null
  },
  regex(val, regex_expr){
    // console.log('**** regex');
    var re = new RegExp(regex_expr);
    return re.test(val)
  },
  getMinValForSumOrderedDesc(arrayVals,limit){
    const vals = [].concat(arrayVals.map(x=>+x)).sort((a, b) => b - a)
    let sum1 = 0
    let minVal = null
    for(let i=0;i<vals.length;i++){
      sum1 += vals[i]
      if(sum1 >= limit){
        minVal = vals[i]
        break
      }
    }
    return minVal
  },

}
